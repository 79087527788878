import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

const faqs = [
  {
    question: "Cómo me contacto con el/la cuidador/a?",
    answer:
      "Podés contactarla por Whatsapp en cualquier momento. La primera vez le enviás un mensaje a través de su perfil público.",
  },
  {
    question: "Cómo funciona la aplicación?",
    answer:
      "Pretal conecta cuidadores con personas que necesitan que alguien cuide a su mascota.",
  },
  {
    question:
      "Puedo tener un encuentro previo con la persona que va a cuidar a mi mascota?",
    answer:
      "Si! Lo recomendamos. Podes coordinar una visita previa a través de Whatsapp para conocer mas a esa persona.",
  },
  {
    question: "Cómo puedo pagar el servicio?",
    answer: "Pagás con Mercadopago a través de la app.",
  },
  {
    question: "Dónde funciona la aplicación?",
    answer: "La aplicación funciona en Argentina.",
  },
  {
    question: "Cuanto cobra Pretal de comisión?",
    answer:
      "Pretal cobra el 12.5%. La comisión restante la cobra Mercadopago dependiendo de cuándo queres recibir el dinero.",
  },
];

const FAQ = () => {
  return (
    <div
      id="faq"
      style={{
        marginLeft: "15px",
        marginRight: "15px",
        marginTop: "50px",
        marginBottom: "50px",
      }}
    >
      <div className="container">
        <div className="section-title text-center">
          <h2>Preguntas frecuentes</h2>
        </div>
        <div className="row">
          {faqs.map((faq, index) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<div className="fa fa-long-arrow-up"></div>}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{ fontSize: "2rem", fontWeight: 600 }}
              >
                {faq.question}
              </AccordionSummary>
              <AccordionDetails style={{ fontSize: "2rem" }}>
                {faq.answer}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
